export const ValidData = (data: any) => {
  if (data) {
    if (isNaN(data)) {
      return data != null && data != undefined;
    }

    return data > 0;
  }

  return false;
};

export const ArrayContains = (key: any, data: Array<any>) => {
  if (key && data && data.length > 0) {
    var tmp_key = key.trim().toLowerCase();

    var index = data.findIndex(
      (data: any) =>
        data && data.category && data.category.trim().toLowerCase() === tmp_key
    );

    return index >= 0;
  }

  return false;
};
