import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Images } from '../../graphql/types';
import { currency } from '../../utils/currency';
import { Section } from '../globalstyles';
import { PageContext } from '../page';
import { IPageContext } from '../types';
import { Approval } from './approval';
import { Brands } from '../utils/brands';
import { isNumber } from '../../utils/utils';

export const FrontPage = () => {
  const context = useContext(PageContext);
  const estate: any = context.estate;
  const [brand, setBrand] = useState(estate?.brand?.name);

  var min_imageSequence = 1;

  if (estate.images && estate.images.length > 0) {
    var min = Math.min.apply(
      null,
      estate.images.map(function (e: Images) {
        return e.imageSequence;
      })
    );

    if (min > min_imageSequence) {
      min_imageSequence = min;
    }
  }

  const getArea = () => {
    if (estate?.textfields?.localAreaName?.text?.trim()?.length)
      return estate?.textfields?.localAreaName?.text?.trim();
    return estate?.address?.municipality;
  };

  return (
    <>
      <Approval
        estateId={context.estate.estateId}
        match={context.match}
        history={context.history}
      />
      <Section
        image={`/images?img=${encodeURIComponent(
          estate.images.find((img: Images) => img.imageSequence === min_imageSequence)
            ?.url
        )}`}
        darken={true}
      >
        <Flex>
          <TopLogo brand={brand} />
          <MainTitle>
            <Big>{getArea()}</Big>
            <Small>{estate.address.street}</Small>
          </MainTitle>
          {context.isProject && (
            <KeyInformation>
              {context.projectInfo?.priceFrom && context.projectInfo?.priceTo && (
                <div>
                  Pris fra:{' '}
                  {currency({
                    number: context.projectInfo?.priceFrom,
                    seperator: ' '
                  })}
                  ,- til{' '}
                  {currency({
                    number: context.projectInfo?.priceTo,
                    seperator: ' '
                  })}
                  ,-
                </div>
              )}
              {context.projectInfo?.usableInternalAreaFrom &&
              context.projectInfo.usableInternalAreaTo ? (
                <div>
                  BRA-I fra: {context.projectInfo?.usableInternalAreaFrom}m&sup2; til{' '}
                  {context.projectInfo?.usableInternalAreaTo}m&sup2;
                </div>
              ) : null}
              {context.projectInfo?.primaryFrom &&
              context.projectInfo?.primaryTo &&
              !(
                context.projectInfo?.usableInternalAreaFrom &&
                context.projectInfo.usableInternalAreaTo
              ) ? (
                <div>
                  P-rom fra: {context.projectInfo?.primaryFrom}m&sup2; til{' '}
                  {context.projectInfo?.primaryTo}m&sup2;
                </div>
              ) : null}
              {context.projectInfo?.usableFrom && context.projectInfo?.usableTo ? (
                <div>
                  BRA fra: {context.projectInfo?.usableFrom}m&sup2; til{' '}
                  {context.projectInfo?.usableTo}m&sup2;
                </div>
              ) : null}
              {context.projectInfo?.btaFrom && context.projectInfo?.btaTo ? (
                <div>
                  BRA fra: {context.projectInfo?.btaFrom}m&sup2; til{' '}
                  {context.projectInfo?.btaTo}m&sup2;
                </div>
              ) : null}
            </KeyInformation>
          )}
          <KeyInformation>
            {estate.price.priceSuggestion ? (
              <div>
                Prisant.:{' '}
                {currency({
                  number: estate.price.priceSuggestion,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {isNumber(estate.price.collectiveDebt) ? (
              <div>
                Fellesgjeld:{' '}
                {currency({
                  number: estate.price.collectiveDebt,
                  seperator: ' '
                })}
                ,-
              </div>
            ) : null}
            {estate.price.totalPrice ? (
              <div>
                Totalt: {currency({ number: estate.price.totalPrice, seperator: ' ' })}
                ,-
              </div>
            ) : null}
            {estate.price.rentPrMonth ? (
              <div>
                Felleskost.:{' '}
                {currency({ number: estate.price.rentPrMonth, seperator: ' ' })}
                ,-
              </div>
            ) : null}
            {estate.size.primaryRoomArea && !estate?.size?.usableInternalArea ? (
              <div>P-rom: {estate.size.primaryRoomArea}m&sup2;</div>
            ) : null}
            {estate.size.usableArea ? (
              <div>BRA: {estate.size.usableArea}m&sup2;</div>
            ) : null}
            {estate?.size?.usableInternalArea ? (
              <div>BRA-I: {estate?.size?.usableInternalArea}m&sup2;</div>
            ) : null}
          </KeyInformation>
        </Flex>
      </Section>
    </>
  );
};

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 50px 0;
  z-index: 1;
`;

const TopLogo = styled.div<{ brand: Brands }>`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  width: 100%;
  //height: 100px;
  position: relative;
  ${(props) => {
    switch (props.brand) {
      case Brands.ABBL:
        return 'background-image: url(/static/ABBL.svg); height: 100px;';
      case Brands.NBBO:
        return 'background-image: url(/static/nbbo.svg); height: 100px;';
      case Brands.MEKLARHUSET:
        return 'background-image: url(/static/meklarhuset.svg); height: 100px;';
      case Brands.MeglerenDin:
        return 'background-image: url(/static/megleren_din_white.svg);';
      case Brands.Meglerhuset:
        return 'background-image: url(/static/meglerhuset/logo_neg.png); height: 200px;';
      case Brands.REMAX:
        return 'background-image: url(/static/remax-logo-white.png); height: 100px;';
      case Brands.OSTLI:
        return 'background-image: url(/static/ostli/ostli_white.svg); height: 100px;';
      case Brands.STORHEIA:
        return 'background-image: url(/static/storheia/storheia_white.svg); height: 100px;';
      case Brands.KOBBL:
        return 'background-image: url(/static/kobbl/logo_white.png); height: 100px;';
      default:
        return 'background-image: url(/static/logo.svg); height: 120px;';
    }
  }}
`;

const MainTitle = styled.div`
  color: #fff;
  z-index: 1;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
`;

const Big = styled.div`
  font-size: 2em;
`;

const Small = styled.div`
  font-size: 1em;
`;

const KeyInformation = styled.div`
  color: #fff;
  z-index: 1;
  text-align: center;
  font-size: 20px;
`;
