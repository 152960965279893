import { apartmentNumberComparator } from '../keyinfo/apartmentNumberComparator';
import { EstateStatus, ProjectInfo, ProjectUnit } from '../types';

export const aggregateProjectData = (projectUnits: any[]): ProjectInfo => {
  const sortableList = [...projectUnits];
  const filterStatus = [EstateStatus.Sold, EstateStatus.Request];
  const notSold = sortableList.filter(
    (unit: ProjectUnit) => !filterStatus.includes(unit.status)
  );
  const minMaxPrice: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    if (a.priceEstimate > b.priceEstimate) return -1;
    if (a.priceEstimate < b.priceEstimate) return 1;
    return 0;
  });
  const priceFrom = minMaxPrice[minMaxPrice.length - 1].priceEstimate;
  const priceTo = minMaxPrice[0].priceEstimate;

  const minMaxTotalPrice: any = notSold
    .filter((f: ProjectUnit) => f.price.totalPrice)
    .sort((a: ProjectUnit, b: ProjectUnit) => {
      if (a.price.totalPrice > b.price.totalPrice) return -1;
      if (a.price.totalPrice < b.price.totalPrice) return 1;
      return 0;
    });
  const totalPriceFrom = minMaxTotalPrice[minMaxTotalPrice.length - 1].price.totalPrice;
  const totalPriceTo = minMaxTotalPrice[0].price.totalPrice;

  const minMaxPrimary: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    if (a.primaryRoomArea > b.primaryRoomArea) return -1;
    if (a.primaryRoomArea < b.primaryRoomArea) return 1;
    return 0;
  });

  const minMaxUsable: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    if (a.usableArea > b.usableArea) return -1;
    if (a.usableArea < b.usableArea) return 1;
    return 0;
  });

  const minMaxGross: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    if (a.size.grossArea > b.size.grossArea) return -1;
    if (a.size.grossArea < b.size.grossArea) return 1;
    return 0;
  });

  const minMaxUsableInternal: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    const av = a?.size?.usableInternalArea ?? 0;
    const bv = b?.size?.usableInternalArea ?? 0;
    if (av > bv) return -1;
    if (av < bv) return 1;
    return 0;
  });

  const minMaxUsableExternal: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    const av = a?.size?.usableExternalArea ?? 0;
    const bv = b?.size?.usableExternalArea ?? 0;
    if (av > bv) return -1;
    if (av < bv) return 1;
    return 0;
  });

  const minMaxUsableEnclosedBalconyArea: any = notSold.sort(
    (a: ProjectUnit, b: ProjectUnit) => {
      const av = a?.size?.usableEnclosedBalconyArea ?? 0;
      const bv = b?.size?.usableEnclosedBalconyArea ?? 0;
      if (av > bv) return -1;
      if (av < bv) return 1;
      return 0;
    }
  );

  const minMaxOpenArea: any = notSold.sort((a: ProjectUnit, b: ProjectUnit) => {
    const av = a?.size?.openArea ?? 0;
    const bv = b?.size?.openArea ?? 0;
    if (av > bv) return -1;
    if (av < bv) return 1;
    return 0;
  });

  const sampleUnit: any = notSold
    .filter((unit: ProjectUnit) => unit.sortApartmentNumber)
    .sort(apartmentNumberComparator)[0];

  return {
    priceFrom,
    priceTo,
    totalPriceFrom,
    totalPriceTo,
    primaryFrom: minMaxPrimary[minMaxPrimary.length - 1].primaryRoomArea,
    primaryTo: minMaxPrimary[0].primaryRoomArea,
    usableFrom: minMaxUsable[minMaxUsable.length - 1].usableArea,
    usableTo: minMaxUsable[0].usableArea,
    btaFrom: minMaxGross[minMaxGross.length - 1].grossArea,
    btaTo: minMaxGross[0].grossArea,
    sampleUnit: sampleUnit,
    usableInternalAreaFrom:
      minMaxUsableInternal[minMaxUsableInternal.length - 1].size?.usableInternalArea ||
      null,
    usableInternalAreaTo: minMaxUsableInternal[0].size?.usableInternalArea || null,
    usableExternalAreaFrom:
      minMaxUsableExternal[minMaxUsableExternal.length - 1].size?.usableExternalArea ||
      null,
    usableExternalAreaTo: minMaxUsableExternal[0].size?.usableExternalArea || null,
    usableEnclosedBalconyAreaFrom:
      minMaxUsableEnclosedBalconyArea[minMaxUsableEnclosedBalconyArea.length - 1].size
        ?.usableEnclosedBalconyArea || null,
    usableEnclosedBalconyAreaTo:
      minMaxUsableEnclosedBalconyArea[0].size?.usableEnclosedBalconyArea || null,
    openAreaFrom: minMaxOpenArea[minMaxOpenArea.length - 1].size?.openArea || null,
    openAreaTo: minMaxOpenArea[0].size?.openArea || null
  };
};
