import React, { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Title, Row } from '../../globalstyles';
import { PageContext } from '../../page';
import { IText } from '../../types';
import { ValidData, ArrayContains } from '../../../utils/utils';
import { Buildings } from '../../keyinfo/buildings';
moment.locale('nb');

// TODO Add field "Tingrettens saksnummer" when available from vitec

export const BruktboligV1 = () => {
  const context = useContext(PageContext);
  const estate = context.estate;

  const owners = estate.contacts.filter((cont: any) => cont.contact_type === 'seller');
  const forretningsforerNavn = estate.contacts.find(
    (cont: any) => cont.contact_type === 'businessManager'
  );
  return (
    <FactsContent>
      <Title>Fakta om eiendommen</Title>
      <Row>
        <div>Oppdragsnummer</div>
        <div>{estate.assignmentNum}</div>
      </Row>
      {/*context.isTvangssalg ? (
        <Row>
          <div>Tingrettens saksnummer</div>
          <div>{estate.assignmentNum}</div>
        </Row>
      ) : null*/}
      <Row>
        <div>Adresse</div>
        <div>{estate.address.address}</div>
      </Row>
      <Row>
        {owners && owners.length > 0 ? (
          <>
            {owners.length > 1 ? <div>Eiere</div> : <div>Eier</div>}
            <Owners>
              {owners?.map((o: any) => (
                <li key={`${o.firstName} ${o.lastName}`}>
                  {o.companyName ? `${o.companyName}` : null}{' '}
                  {o.companyName && (o.firstName || o.lastName) ? ',' : null}{' '}
                  {o.firstName} {o.lastName}
                </li>
              ))}
            </Owners>
          </>
        ) : null}
      </Row>
      {forretningsforerNavn &&
      forretningsforerNavn.companyName &&
      ValidData(forretningsforerNavn.companyName) ? (
        <Row>
          <div>Forretningsførers navn</div>
          <div>{forretningsforerNavn.companyName}</div>
        </Row>
      ) : null}
      {estate.matrikkel?.gnr && ValidData(estate.matrikkel?.gnr) ? (
        <Row>
          <div>Gårdsnummer</div>
          <div>{estate.matrikkel.gnr}</div>
        </Row>
      ) : null}
      {estate.matrikkel?.bnr && ValidData(estate.matrikkel?.bnr) ? (
        <Row>
          <div>Bruksnummer</div>
          <div>{estate.matrikkel.bnr}</div>
        </Row>
      ) : null}
      {estate.partName && ValidData(estate.partName) ? (
        <Row>
          <div>{estate.ownership === 'cooprative' ? 'Borettslag' : 'Sameie'}</div>
          <div>{estate.partName}</div>
        </Row>
      ) : null}
      {estate.partOrgNumber && ValidData(estate.partOrgNumber) ? (
        <Row>
          <div>Org.nr</div>
          <div>{estate.partOrgNumber}</div>
        </Row>
      ) : null}
      {estate.partNumber && ValidData(estate.partNumber) ? (
        <Row>
          <div>Andelsnummer</div>
          <div>{estate.partNumber}</div>
        </Row>
      ) : null}
      {estate.size.primaryRoomAreaDescription &&
      ValidData(estate.size.primaryRoomAreaDescription) ? (
        <p>
          <strong>Arealbeskrivelse</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.size.primaryRoomAreaDescription.trim().replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate?.textfields?.valuatorCommentsToArea?.text &&
      ValidData(estate?.textfields?.valuatorCommentsToArea?.text) ? (
        <p>
          <strong>Takstmannens kommentar til arealoppmåling:</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate?.textfields?.valuatorCommentsToArea?.text
                ?.trim()
                ?.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate?.buildings?.length ? (
        <BuildingsWrapper>
          <Buildings />
        </BuildingsWrapper>
      ) : null}
      {estate?.textfields?.nonMeasurableAreas?.text &&
      ValidData(estate?.textfields?.nonMeasurableAreas?.text) ? (
        <p>
          <strong>ALH/GUA:</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate?.textfields?.nonMeasurableAreas?.text
                ?.trim()
                ?.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate?.textfields?.fellesarealerOgRettigheter?.text &&
      ValidData(estate?.textfields?.fellesarealerOgRettigheter?.text) ? (
        <p>
          <strong>Fellesarealer og rettigher til bruk:</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate?.textfields?.fellesarealerOgRettigheter?.text
                ?.trim()
                ?.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.size.plot_description && ValidData(estate.size.plot_description) ? (
        <p>
          <strong>Beskrivelse av tomt:</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.size.plot_description.trim().replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.preemptive && ValidData(estate.preemptive) ? (
        <p>
          <strong>Forkjøpsrett</strong>
          <br />
          {estate.preemptive}
        </p>
      ) : null}
      {estate.preEmptiveDeadline && ValidData(estate.preEmptiveDeadline) ? (
        <p>
          <strong>Forkjøpsrettsfrist</strong>
          <br />
          {moment(estate.preEmptiveDeadline).format('DD.MM.YYYY')}
        </p>
      ) : null}
      {estate.preEmptiveFee && ValidData(estate.preEmptiveFee) ? (
        <p>
          <strong>Forkjøpsrettsgebyr</strong>
          <br />
          {estate.preEmptiveFee},-
        </p>
      ) : null}
      {estate.boardApproval && ValidData(estate.boardApproval) ? (
        <p>
          <strong>Styregodkjennelse</strong>
          <br />
          {estate.boardApproval}
        </p>
      ) : null}
      {estate.accountingBudgeting && ValidData(estate.accountingBudgeting) ? (
        <p>
          <strong>Regnskap/Budsjett</strong>
          <br />
          {estate.accountingBudgeting}
        </p>
      ) : null}
      {estate.guaranteeFund && ValidData(estate.guaranteeFund) ? (
        <p>
          <strong>Sikringsordning/fellesgjeld</strong>
          <br />
          {estate.guaranteeFund}
        </p>
      ) : null}
      {estate.jointDebtDescription && ValidData(estate.jointDebtDescription) ? (
        <p>
          <strong>Kommentar fellesgjeld</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.jointDebtDescription.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.loanTermsCollectiveDebt && ValidData(estate.loanTermsCollectiveDebt) ? (
        <p>
          <strong>Lånebetingelser fellesgjeld</strong>
          <br />
          {estate.loanTermsCollectiveDebt}
        </p>
      ) : null}
      {estate.amountYearsInstallmentFree &&
      ValidData(estate.amountYearsInstallmentFree) ? (
        <p>
          <strong>Antall år avdragsfritt</strong>
          <br />
          {estate.amountYearsInstallmentFree}
        </p>
      ) : null}
      {estate.takeoverComment &&
      ValidData(estate.takeoverComment) &&
      !ArrayContains('takeoverComment', estate.textContent) ? (
        <p>
          <strong>Overtakelse</strong>
          <br />
          {estate.takeoverComment}
        </p>
      ) : null}
      {estate.jointDebtInterest && ValidData(estate.jointDebtInterest) ? (
        <p>
          <strong>Rentekostnader fellesgjeld</strong>
          <br />
          {estate.jointDebtInterest},-
        </p>
      ) : null}
      {estate.price.communityTax && ValidData(estate.price.communityTax) ? (
        <Row>
          <div>Kommunale avgifter</div>
          <div>{estate.price.communityTax},-</div>
        </Row>
      ) : null}
      {estate.price.communityTaxYear && ValidData(estate.price.communityTaxYear) ? (
        <Row>
          <div>Kommunale avgifter pr. år</div>
          <div>{estate.price.communityTaxYear}</div>
        </Row>
      ) : null}
      {estate.propertyTaxAmount && ValidData(estate.propertyTaxAmount) ? (
        <Row>
          <div>Eiendomsskatt</div>
          <div>{estate.propertyTaxAmount},-</div>
        </Row>
      ) : null}
      {estate.propertyTaxYear && ValidData(estate.propertyTaxYear) ? (
        <Row>
          <div>Eiendomsskatt år</div>
          <div>{estate.propertyTaxYear}</div>
        </Row>
      ) : null}

      {estate.price.yearlySocietyTax && ValidData(estate.price.yearlySocietyTax) ? (
        <Row>
          <div>Årlig velavgift</div>
          <div>{estate.price.yearlySocietyTax},-</div>
        </Row>
      ) : null}
      {estate.price.waterRate && ValidData(estate.price.waterRate) ? (
        <Row>
          <div>Vannavgift</div>
          <div>{estate.price.waterRate},-</div>
        </Row>
      ) : null}
      {estate.price.waterRateYear && ValidData(estate.price.waterRateYear) ? (
        <Row>
          <div>Vannavgift år</div>
          <div>{estate.price.waterRateYear}</div>
        </Row>
      ) : null}
      {estate.price.waterRateDescription &&
      ValidData(estate.price.waterRateDescription) ? (
        <p>
          <strong>Info vannavgift</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.price.waterRateDescription.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.estateJointOwnershipFraction &&
      ValidData(estate.estateJointOwnershipFraction) ? (
        <Row>
          <div>Sameiebrøk</div>
          <div>{estate.estateJointOwnershipFraction}</div>
        </Row>
      ) : null}
      {estate.partAbout && ValidData(estate.partAbout) ? (
        <p>
          <strong>{PartAboutTitle(estate.ownership)}</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.partAbout.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.residentCommitments && ValidData(estate.residentCommitments) ? (
        <p>
          <strong>Beboernes forpliktelser og dugnader</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.residentCommitments.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.animals && ValidData(estate.animals) ? (
        <p>
          <strong>Dyrehold</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.animals.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.statutes && ValidData(estate.statutes) ? (
        <p>
          <strong>Vedtekter/husordensregler</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.statutes.replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}

      {/* AREAL */}

      {estate?.textContent &&
        estate?.textContent?.map((text: IText) => {
          return (
            <div key={text.title}>
              <p>
                <strong>{text.title}</strong>
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: text.text.replace(/(?:\r\n|\r|\n)/g, '<br>')
                  }}
                />
              </p>
            </div>
          );
        })}
      {estate.agent.responsible?.name || estate.agent.main?.name ? (
        <p>
          <strong>Ansvarlig megler</strong>
          <br />
          {estate.agent.responsible?.name
            ? estate.agent.responsible.name
            : estate.agent.main.name}
        </p>
      ) : null}
      {context.created ? (
        <p>
          <strong>Produksjonsdato</strong>
          <br />
          {moment(context.created).format('DD.MM.YYYY')} kl.{' '}
          {moment(context.created).format('HH:mm')} v{context.version}
        </p>
      ) : null}
    </FactsContent>
  );
};

const FactsContent = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
`;

const Owners = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    text-align: right;
  }
`;

const BuildingsWrapper = styled.div`
  display: flex;
  margin: 1em 0;
  width: 100%;
`;

const PartAboutTitle = (ownership: any) => {
  if (ownership) {
    try {
      var title = null;

      switch (ownership.trim().toLowerCase()) {
        case 'cooprative':
          {
            title = 'Om borettslaget';
          }
          break;
        case 'stock':
          {
            title = 'Om aksjeselskapet';
          }
          break;
        default:
          {
            title = 'Om sameiet';
          }
          break;
      }

      if (title) return title;
    } catch {}
  }

  return 'Om sameiet';
};
