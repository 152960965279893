import React, { useContext } from 'react';
import styled from 'styled-components';
import { Section, Title, TitleUpper } from '../globalstyles';
import { PageContext } from '../page';

const phone = (phone: string): string => {
  if (!phone || phone.length === 0) {
    return '';
  }
  const phonenumber = phone.replace('+47', '');
  const number = [
    phonenumber.slice(0, 2),
    phonenumber.slice(2, 4),
    phonenumber.slice(4, 6),
    phonenumber.slice(6, 8),
  ];
  return number.join(' ');
};

export const Agent = () => {
  const context = useContext(PageContext);
  const estate = context.estate;
  return (
    <>
      <AgentSection color="#EDECE8">
        <div>
          <AgentPhoto
            url={`https://www.garanti.no/files/ansatt/${estate.agent.main.name
              .split(/-| /)
              .join('_')}.jpg`}
          />

          <TitleUpper>{estate.agent.main.title}</TitleUpper>
          <AgentTitle>{estate.agent.main.name}</AgentTitle>
          <div>{estate.agent.main.email}</div>
          <div>{phone(estate.agent.main.mobilePhone)}</div>
        </div>
      </AgentSection>
      {estate?.agent?.assistant?.name && (
        <AgentSection color="#EDECE8">
          <div>
            <AgentPhoto
              url={`https://www.garanti.no/files/ansatt/${estate.agent.assistant.name
                .split(/-| /)
                .join('_')}.jpg`}
            />
            <TitleUpper>{estate.agent.assistant.title}</TitleUpper>
            <AgentTitle>{estate.agent.assistant.name}</AgentTitle>
            <div>{estate.agent.assistant.email}</div>
            <div>{phone(estate.agent.assistant.mobilePhone)}</div>
          </div>
        </AgentSection>
      )}
    </>
  );
};

const AgentSection = styled(Section)`
  text-align: center;
  height: auto;
`;

const AgentPhoto = styled.div<{ url: string }>`
  ${(props) => `background-image: url(${props.url});`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin: 0 auto 1em;
`;

const AgentTitle = styled(Title)`
  margin: 0;
`;
