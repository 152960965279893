import React, { useContext } from 'react';
import styled from 'styled-components';
import { PageContext } from '../../page';
import { IBuilding } from './types';
import { BuildingAreas } from './BuildingAreas';

export const Buildings = () => {
  const context = useContext(PageContext);
  const buildings: IBuilding[] = context?.estate?.buildings ?? [];
  const info = context?.estate?.textfields?.nonMeasurableAreas;
  if (!Array.isArray(buildings)) return null;
  if (!buildings?.length) return null;
  //const list = [...buildings, ...buildings];

  return (
    <Container>
      {buildings?.map((building, index) => {
        if (!building) return null;
        const { name, areas } = building;
        if (!areas?.length) return null;
        return (
          <StyledBuilding key={`building-${index}`}>
            {name || buildings?.length > 1 ? (
              <StyledHeading>
                {name?.length ? name : `Bygning ${index + 1}`}
              </StyledHeading>
            ) : null}
            <BuildingAreas areas={areas} />
          </StyledBuilding>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
`;

const StyledBuilding = styled(Container)`
  gap: 0.5em;
`;

const StyledHeading = styled.div`
  font-weight: 500;
`;
