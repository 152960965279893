import React, { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Title, Row } from '../../globalstyles';
import { PageContext } from '../../page';
import { IText } from '../../types';
import { ValidData } from '../../../utils/utils';
moment.locale('nb');

// TODO Add field "Tingrettens saksnummer" when available from vitec

export const TemplateV2 = () => {
  const context = useContext(PageContext);
  const estate = context.estate;

  const owners = estate.contacts.filter((cont: any) => cont.contact_type === 'seller');
  const forretningsforerNavn = estate.contacts.find(
    (cont: any) => cont.contact_type === 'businessManager'
  );

  return (
    <FactsContent>
      <Title>Fakta om eiendommen</Title>
      <Row>
        <div>Oppdragsnummer</div>
        <div>{estate.assignmentNum}</div>
      </Row>
      {/*context.isTvangssalg ? (
        <Row>
          <div>Tingrettens saksnummer</div>
          <div>{estate.assignmentNum}</div>
        </Row>
      ) : null*/}
      <Row>
        <div>Adresse</div>
        <div>{estate.address.address}</div>
      </Row>
      <Row>
        {owners && owners.length > 0 ? (
          <>
            {owners.length > 1 ? <div>Eiere</div> : <div>Eier</div>}
            <Owners>
              {owners.map((o: any) => (
                <li key={`${o.firstName} ${o.lastName}`}>
                  {o.companyName ? `${o.companyName}` : null}{' '}
                  {o.companyName && (o.firstName || o.lastName) ? ',' : null}{' '}
                  {o.firstName} {o.lastName}
                </li>
              ))}
            </Owners>
          </>
        ) : null}
      </Row>
      {forretningsforerNavn &&
      forretningsforerNavn.companyName &&
      ValidData(forretningsforerNavn.companyName) ? (
        <Row>
          <div>Forretningsførers navn</div>
          <div>{forretningsforerNavn.companyName}</div>
        </Row>
      ) : null}
      {estate.matrikkel?.gnr && ValidData(estate.matrikkel?.gnr) ? (
        <Row>
          <div>Gårdsnummer</div>
          <div>{estate.matrikkel.gnr}</div>
        </Row>
      ) : null}
      {estate.matrikkel?.bnr && ValidData(estate.matrikkel?.bnr) ? (
        <Row>
          <div>Bruksnummer</div>
          <div>{estate.matrikkel.bnr}</div>
        </Row>
      ) : null}
      {estate.size.plot_description && ValidData(estate.size.plot_description) ? (
        <p>
          <strong>Beskrivelse av tomt:</strong>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: estate.size.plot_description.trim().replace(/\n/g, '<br>')
            }}
          />
        </p>
      ) : null}
      {estate.textContent.map((text: IText, index: number) => {
        return (
          <div key={`t${index}`}>
            <p>
              <strong>{text.title}</strong>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: text.text.replace(/(?:\r\n|\r|\n)/g, '<br>')
                }}
              />
            </p>
          </div>
        );
      })}
      {estate.agent.responsible?.name || estate.agent.main?.name ? (
        <p>
          <strong>Ansvarlig megler</strong>
          <br />
          {estate.agent.responsible?.name
            ? estate.agent.responsible.name
            : estate.agent.main.name}
        </p>
      ) : null}
      {context.created ? (
        <p>
          <strong>Produksjonsdato</strong>
          <br />
          {moment(context.created).format('DD.MM.YYYY')} kl.{' '}
          {moment(context.created).format('HH:mm')} v{context.version}
        </p>
      ) : null}
    </FactsContent>
  );
};

const FactsContent = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
`;

const Owners = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    text-align: right;
  }
`;
