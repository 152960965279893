export enum Brands {
  ABBL = "ABBL",
  NBBO = "NBBO",
  MEKLARHUSET = "Meklarhuset",
  GARANTI = "GARANTI",
  MeglerenDin = "Megleren Din",
  Meglerhuset = "Meglerhuset",
  REMAX = "REMAX",
  STORHEIA = "Storheia",
  OSTLI = "Østli Eiendomsmegling",
}

export const getClassName = (brand?: Brands) => {
  if (!brand) return;
  return brand?.toLowerCase()?.trim()?.replace(" ", "-");
};
