import React, { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { currency } from '../../utils/currency';
import { Row } from '../globalstyles';
import { EstateTypes } from '../types';
import { PageContext } from '../page';
import { ValidData } from '../../utils/utils';
import e from 'express';
import { Buildings } from './buildings';

moment.locale('nb');

export const KeyInfoData = () => {
  const context = useContext(PageContext);
  const estate = context.estate;
  const stars = context.meta?.stars;
  if (!estate) {
    return null;
  }

  return (
    <div>
      {estate.price.priceSuggestion && estate.price.collectiveDebt && (
        <Row>
          <div>Totalt</div>
          <div>
            Kr{' '}
            {currency({
              number: estate.price.priceSuggestion + estate.price.collectiveDebt,
              seperator: ' '
            })}
            ,-
          </div>
        </Row>
      )}
      {context.projectInfo?.totalPriceFrom && context.projectInfo?.totalPriceTo && (
        <TotalPris>
          Totalpris fra:{' '}
          {currency({
            number: context.projectInfo?.totalPriceFrom,
            seperator: ' '
          })}
          ,- til{' '}
          {currency({
            number: context.projectInfo?.totalPriceTo,
            seperator: ' '
          })}
          ,-
        </TotalPris>
      )}
      {estate.price.priceSuggestion && ValidData(estate.price.priceSuggestion) && (
        <Row>
          <div>Prisantydning</div>
          <div>
            Kr{' '}
            {currency({
              number: estate.price.priceSuggestion,
              seperator: ' '
            })}
            ,-
          </div>
        </Row>
      )}
      {estate.price.collectiveDebt && ValidData(estate.price.collectiveDebt) && (
        <Row>
          <div>Fellesgjeld</div>
          <div>Kr {currency({ number: estate.price.collectiveDebt })},-</div>
        </Row>
      )}
      {estate.shareJointDebtToDate && ValidData(estate.shareJointDebtToDate) ? (
        <Row>
          <div>Andel felles gjeld pr. dato</div>
          <div>{moment(estate.shareJointDebtToDate).format('DD.MM.YYYY')}</div>
        </Row>
      ) : null}
      {estate.price.totalPrice && ValidData(estate.price.totalPrice) && (
        <Row>
          <div>Totalt inkl. omk {PrintStars('totalPrice', stars)}</div>
          <div>
            Kr {currency({ number: estate.price.totalPrice, seperator: ' ' })}
            ,-
          </div>
        </Row>
      )}
      {estate.price.rentPrMonth && ValidData(estate.price.rentPrMonth) && (
        <Row>
          <div>Felleskost. totalt {PrintStars('rentPrMonth', stars)}</div>
          <div>
            Kr {currency({ number: estate.price.rentPrMonth, seperator: ' ' })}
            ,-
          </div>
        </Row>
      )}
      {estate.price.rentIncludes && ValidData(estate.price.rentIncludes) && (
        <p>
          <strong>Felleskost inkluderer:</strong>
          <br />
          {estate.price.rentIncludes}
        </p>
      )}
      {estate.joinCostAfterGracePeriod && ValidData(estate.joinCostAfterGracePeriod) ? (
        <p>
          <strong>Felleskostnader etter avdragsfri periode:</strong>
          <br />
          {estate.joinCostAfterGracePeriod}
        </p>
      ) : null}
      {estate.price?.collectiveAssets && ValidData(estate.price?.collectiveAssets) ? (
        <Row>
          <div>Andel felles formue</div>
          <div>
            Kr{' '}
            {currency({
              number: estate.price.collectiveAssets,
              seperator: ' '
            })}
            ,-
          </div>
        </Row>
      ) : null}
      {estate.shareJointCapitalDate && ValidData(estate.shareJointCapitalDate) ? (
        <Row>
          <div>Andel felles formue pr. dato</div>
          <div>{moment(estate.shareJointCapitalDate).format('DD.MM.YYYY')}</div>
        </Row>
      ) : null}
      {estate.primaryValue && ValidData(estate.primaryValue) ? (
        <Row>
          <div>Ligningsverdi primærbolig</div>
          <div>
            Kr {currency({ number: estate.primaryValue, seperator: ' ' })}
            ,- {estate.primaryYear && <>pr. år {estate.primaryYear}</>}
          </div>
        </Row>
      ) : null}
      {estate.secondaryValue && ValidData(estate.secondaryValue) ? (
        <Row>
          <div>Ligningsverdi sekundærbolig</div>
          <div>
            Kr {currency({ number: estate.secondaryValue, seperator: ' ' })}
            ,- pr. år {estate.secondaryYear}
          </div>
        </Row>
      ) : null}
      {estate.valuationTaxComment && ValidData(estate.valuationTaxComment) ? (
        <p>
          <strong>Info formuesverdi</strong>
          <br />
          {estate.valuationTaxComment}
        </p>
      ) : null}
      {estate.size.usableArea &&
      estate.size.primaryRoomArea &&
      !estate?.size?.usableInternalArea ? (
        <Row>
          <div>BRA/P-ROM</div>
          <div>
            {estate.size.usableArea}/{estate.size.primaryRoomArea}
          </div>
        </Row>
      ) : null}
      {estate.size.usableArea && estate?.size?.usableInternalArea ? (
        <Row>
          <div>BRA</div>
          <div>{estate.size.usableArea}</div>
        </Row>
      ) : null}
      {estate?.size?.usableInternalArea ? (
        <Row>
          <div>BRA-I (internt bruksareal)</div>
          <div>{estate?.size?.usableInternalArea}</div>
        </Row>
      ) : null}
      {estate?.size?.usableExternalArea ? (
        <Row>
          <div>BRA-E (eksternt bruksareal)</div>
          <div>{estate?.size?.usableExternalArea}</div>
        </Row>
      ) : null}
      {estate?.size?.usableEnclosedBalconyArea ? (
        <Row>
          <div>BRA-B (innglasset balkong)</div>
          <div>{estate?.size?.usableEnclosedBalconyArea}</div>
        </Row>
      ) : null}
      {estate?.size?.openArea ? (
        <Row>
          <div>TBA (terrasse-/balkongareal)</div>
          <div>{estate?.size?.openArea}</div>
        </Row>
      ) : null}
      {estate.noOfRooms && estate.noOfBedRooms ? (
        <Row>
          <div>Rom (Soverom) {PrintStars('noOfBedRooms', stars)}</div>
          <div>
            {estate.noOfRooms} ({estate.noOfBedRooms})
          </div>
        </Row>
      ) : null}
      {estate.noOfBathRooms && ValidData(estate.noOfBathRooms) ? (
        <Row>
          <div>Ant. bad</div>
          <div>{estate.noOfBathRooms}</div>
        </Row>
      ) : null}
      {estate.price.estimatedValue && ValidData(estate.price.estimatedValue) ? (
        <Row>
          <div>Verditakst</div>
          <div>
            Kr{' '}
            {currency({
              number: estate.price.estimatedValue,
              seperator: ' '
            })}
            ,-
          </div>
        </Row>
      ) : null}
      {estate.ownershipDescription && ValidData(estate.ownershipDescription) ? (
        <Row>
          <div>Eierform</div>
          <div>{estate.ownershipDescription}</div>
        </Row>
      ) : null}
      {estate.estateType && ValidData(estate.estateType) ? (
        <Row>
          <div>Boligtype</div>
          <div>
            {estate.estateBaseType === EstateTypes.Prosjekt
              ? 'Prosjekt'
              : estate.estateType}
          </div>
        </Row>
      ) : null}
      {estate.constructionYear && ValidData(estate.constructionYear) ? (
        <Row>
          <div>Byggeår</div>
          <div>{estate.constructionYear}</div>
        </Row>
      ) : null}
      {estate.floor && ValidData(estate.floor) ? (
        <Row>
          <div>Etasje</div>
          <div>{estate.floor}</div>
        </Row>
      ) : null}
      {estate.energy_color && estate.energy_letter ? (
        <Row>
          <div>Energimerke</div>
          <div>
            <EnergyBox
              style={{
                backgroundColor: estate.energy_color
              }}
            >
              {' '}
              {estate.energy_letter}
            </EnergyBox>
          </div>
        </Row>
      ) : null}
      {estate.size.plot_size && ValidData(estate.size.plot_size) ? (
        <Row>
          <div>Tomt</div>
          <div>
            {estate.size.plot_size} m&sup2;
            {estate.size.plot_owned === 1 ? ' (eiet)' : ' (festet)'}
          </div>
        </Row>
      ) : null}
      {/*estate?.textfields?.nonMeasurableAreas?.text &&
      ValidData(estate?.textfields?.nonMeasurableAreas?.text) ? (
        <Row>
          <div>ALH/GUA:</div>
          <div>{estate?.textfields?.nonMeasurableAreas?.text}</div>
        </Row>
      ) : null}

      {estate.size.plot_description && ValidData(estate.size.plot_description) ? (
        <p>
          <strong>Beskrivelse av tomt:</strong>
          <br />
          {estate.size.plot_description}
        </p>
      ) : null*/}
      {stars && stars.length > 0 && (
        <Forbehold>
          <tbody>
            {stars.map((e: any) => {
              return (
                <tr key={`stars_${e.key}`}>
                  <td>{e.star}</td>
                  <td>{e.text}</td>
                </tr>
              );
            })}
          </tbody>
        </Forbehold>
      )}
    </div>
  );
};

const PrintStars = (key: any, stars: Array<any>) => {
  if (key && key.length > 0 && stars && stars.length > 0) {
    var tmp_key = key.trim().toLowerCase();
    var obj = stars.find(
      (data: any) => data.key && data.key.trim().toLowerCase() === tmp_key
    );

    if (obj && obj.star && obj.star.trim().length > 0) {
      return obj.star.trim();
    }
  }

  return '';
};

const Forbehold = styled.table`
  margin-top: 4em;
  font-size: 0.8em;
  td:first-child {
    vertical-align: top;
  }
`;

const EnergyBox = styled.div`
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
`;

const TotalPris = styled.div`
  margin: 0em auto 2em;
  text-align: center;
  font-size: 1.2em;
`;
